.newsletter-section {
    background: #ffffff;
    color: #fff;
    padding: 31px 0px;
    text-align: center;
}
.newsletter-section h2 {
    font-size: 25px;
    margin-bottom: 2px;
    color: #ed1c24;
    font-weight: 600;
}

.newsletter-section p {
    font-size: 14px;
    margin-bottom: 26px;
    color: #000;
}

.newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsletter-form input[type="email"] {
    padding: 12px;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    font-size: 14px;
    background-color: #f4f4f4;
    color: #000;
}

.newsletter-form button {
    padding: 12px 20px;
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: #37afb3;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
    background-color: #269296;
}
.FaWhatsapp{
	position:fixed;
	width:54px;
	height:54px;
	bottom:96px;
	right:23px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px rgba(0,0,0, 0.2);
  z-index:100;
}
a.FaWhatsapp:hover {
    color: #fff;
}

