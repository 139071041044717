

.terms-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.terms-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.terms-header h1 {
    font-size: 32px;
    margin: 0;
    color: #333;
}

.terms-header p {
    font-size: 16px;
    color: #666;
}

.terms-content {
    padding: 20px;
}

.terms-content h2 {
    font-size: 18px;
    color: #444;
    margin-top: 20px;
}

.terms-content p, .terms-content ul {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.terms-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.terms-content ul li {
    margin-bottom: 10px;
}

.terms-content strong {
    color: #333;
}

.terms-container section {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .terms-container {
        padding: 15px;
    }

    .terms-content h2 {
        font-size: 22px;
    }

    .terms-content p, .terms-content ul {
        font-size: 15px;
    }
}