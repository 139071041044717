/* ==================
CSS Indexing
=====================
1. Reset-CSS
2. Button-CSS
3. Helper-Class-CSS
4. Section-Title-CSS
5. Icon-List-CSS
6. Preloader-CSS
7. Mainmenu-CSS
8. Header-CSS
9. Features-CSS
10. Counter-CSS
11. Overview-CSS
12. Video-CSS
13. Call-to-Action-CSS
14. Testimonial-CSS
15. Product-CSS
16. FAQ-CSS
17. Footer-CSS
===================*/

:root {
    --primary-color: #37afb3;
    --secondary-color: #ed1c24;
    --body-color: #696969;
    --heading-color: #131313;
    --navbar-height: 94px;
    --white-color: #fff;
}

.theme-color-text {
    color: var(--secondary-color) !important;
}
.theme-color-bg {
    background-color : var(--primary-color) !important;
}
/*=== 1. Reset-CSS ===*/

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--body-color);
}
.btn-primary {
    background-color : var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}


P:last-child {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 16px;
}

a:focus,
a:hover,
a {
    text-decoration: none !important;
    outline: none !important;
}

a {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

img {
    max-width: 100%;
}

.row>div {
    position: relative;
    z-index: 1;
}

.container {
    max-width: 1312px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}
/*=== 2. Button-CSS ===*/



.primary-button {
    background: var(--primary-color);
    color: #ffffff;
    display: inline-block;
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 0px rgba(255, 112, 49, 0);
    box-shadow: 0px 0px 0px rgba(255, 112, 49, 0);
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.primary-button.primary-button-2 {
    background-color: #131313;
 }

.primary-button:hover {
    color: #ffffff;
}

.primary-button .part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 32px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100px;
}

.primary-button .back {
    position: absolute;
    top: 100%;
    left: 0%;
    background-color: var(--heading-color);
    color: #ffffff;
}
.primary-button.primary-button-2 .back {
    background-color: var(--primary-color);
}

.primary-button.button-active .part,
.primary-button:not(.toggle):hover .part {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.primary-button .icon {
    font-size: 80%;
    margin-left: 10px;
}

.primary-button.white-button .front {
    background-color: #ffffff;
    color: var(--primary-color);
}

.primary-button.white-button .back {
    background-color: var(--primary-color);
    color: #ffffff;
}

/*=== 3. Helper-Class-CSS ===*/

.page-wrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 100%;
}
.section-padding {
    padding-top: 52px;
    padding-bottom: 71px;
}

.section-padding-top {
    padding-top: 132px;
}

.section-padding-bottom {
    padding-bottom: 132px;
}

.mb-n40 {
    margin-bottom: -40px;
}

.z2 {
    z-index: 6 !important;
    position: relative;
}

/*=== 4. Section-Title-CSS ===*/

.section-title {
    text-align: center;
    margin-bottom: 56px;
}

.section-title .title {
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: 700;
}

.section-title.white-title .title {
    color: #ffffff;
}

.section-title.white-title .desc {
    color: #ffffff;
}

/*=== 6. Preloader-CSS ===*/

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    pointer-events: none;
    z-index: 999;
}

.preloader .load {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--primary-color);
    opacity: 0.1;
}

.preloader .count {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 18vw;
    color: var(--primary-color);
    font-weight: 700;
    text-align: center;
    display: inline-block;
}

/*=== 8. Mainmenu-CSS ===*/

.mainmenu-area {
    width: 100%;
    z-index: 99;
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
}

.mainmenu-area .container {
    min-height: var(--navbar-height);
    display: flex;
    align-items: center;
}

.mainmenu-area.sticky .mainmenu-area {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0px 0px 21px -14px #000;
}

.mainmenu-area .nav-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 0;
}

.mainmenu-area .nav-row .nav-logo {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

.mainmenu-area .nav-row .nav-logo .logo img {
    display: inline-block;
    width: 110px;
}

.transparent-light .mainmenu-area:not(.sticky) .nav-row .nav-logo .dark-logo {
    display: none;
}

.mainmenu-area .logo.dark-logo {
    display: none;
}

.mainmenu-area.sticky .logo.dark-logo {
 display: block;
}

.mainmenu-area.sticky .logo.light-logo {
    display: none;
}
.transparent-dark .mainmenu-area .nav-row .nav-logo .light-logo {
    display: none;
}

.mainmenu-area .nav-actions {
    order: 1;
    display: flex;
    align-items: center;
}

.mainmenu-area .nav-actions .primary-button {
    margin-left: 24px;
    border-radius: 100px;
    background-color: transparent !important;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.07);
}

.mainmenu-area .nav-actions .primary-button:hover {
    box-shadow: 0px 4px 4px rgba(255, 107, 49, 0.2);
}

.mainmenu-area .nav-actions .primary-button .front {
    background-color: #ffffff;
    color: var(--heading-color);
}

.mainmenu-area:not(.sticky) .nav-actions .primary-button .front {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.07);
}

.mainmenu-area .nav-actions .primary-button .back {
    background-color: var(--primary-color);
}

.mainmenu-area .nav-actions .primary-button.cirlce .part {
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 20px;
    color: #ffffff;
    
}

.mainmenu-area.sticky .nav-actions .primary-button.cirlce .part {
    color: var(--heading-color);
}

.ls-bar {
    font-size: 80%;
}

.mainmenu-area .nav-actions .primary-button.menu-toggle {
    display: none;
}

.mainmenu-area .nav-row .menu-items {
    display: flex;
    align-items: end;
    justify-content: end;
    flex-grow: 8;
}

.mainmenu-area .nav-row .menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.mainmenu-area .nav-row .menu-items ul li {
    position: relative;
}

.mainmenu-area .nav-row .menu-items ul li a {
    color: var(--white-color);
    display: inline-block;
}

.mainmenu-area .nav-row .menu-items ul li > a {
    line-height: 96px;
}

.mainmenu-area .nav-row .menu-items ul li:last-child a {
    line-height: initial;
}
.mainmenu-area .nav-row .menu-items ul li ul li a {
    line-height: 30px;
}
.mainmenu-area.sticky .nav-row .menu-items ul li a {
    color: var(--heading-color);
    display: inline-block;
}

.mainmenu-area .nav-row .menu-items ul li>a.active,
.mainmenu-area .nav-row .menu-items ul li:hover>a {
    color: var(--primary-color);
}
.mainmenu-area.sticky .nav-row .menu-items ul li>a.active, .mainmenu-area .nav-row .menu-items ul li:hover>a {
    color: var(--primary-color);
}
.mainmenu-area.sticky .nav-row .menu-items ul li>a.bookConsultant.active, .mainmenu-area .nav-row .menu-items ul li:hover>a.bookConsultant, .mainmenu-area.sticky .nav-row .menu-items ul li a.bookConsultant  {
    color: #ffffff;
}
.mainmenu-area .nav-row .menu-items>ul {
    display: flex;
    justify-content: end;
}

.mainmenu-area .nav-row .menu-items>ul>li {
    padding: 0 10px;
    display: flex;
    align-items: center;
}


.mainmenu-area .nav-row .menu-items>ul>li>a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: var(--white-color);
    border-radius: 10px 10px 0 0;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
}

.mainmenu-area .nav-row .menu-items>ul>li>a:after {
    
    background-color: var(--primary-color);
}
.mainmenu-area .nav-row .menu-items ul li>a.active:after {
    opacity: 1;
}

.mainmenu-area .nav-row .menu-items ul li>.plus {
    width: 12px;
    height: 24px;
    position: relative;
    cursor: pointer;
    margin-top: 3px;
    margin-left: 8px;
}

.mainmenu-area .nav-row .menu-items ul li li>.plus {
    float: right;
}

.mainmenu-area .nav-row .menu-items ul li>.plus:before,
.mainmenu-area .nav-row .menu-items ul li>.plus:after {
    content: "";
    width: 12px;
    height: 2px;
    display: block;
    background-color: var(--heading-color);
    position: absolute;
    left: calc(50% - 6px);
    top: calc(50% - 2px);
    transition: 0.5s;
}

.mainmenu-area .nav-row .menu-items ul li>a.active~.plus:before,
.mainmenu-area .nav-row .menu-items ul li>a.active~.plus:after,
.mainmenu-area .nav-row .menu-items ul li:hover>.plus:before,
.mainmenu-area .nav-row .menu-items ul li:hover>.plus:after {
    background-color: var(--heading-color);
}

.mainmenu-area .nav-row .menu-items ul li>.plus:before {
    transform: rotate(90deg);
}

.mainmenu-area .nav-row .menu-items ul li.menu-open>.plus:before {
    transform: rotate(0deg);
}
.bookConsultant
{
    background-color: #37afb3;
    padding: 9px 25px;
    border-radius: 7px;
    line-height: 23px;
}
/*=== 9. Sub-Menu-CSS ===*/

.mainmenu-area .nav-row .menu-items li ul {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 16px 0;
    transition: 0.5s;
    transform: scaleY(0);
    transform-origin: top left;
    z-index: 2;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    width: 309px;
    height: 163px;
    overflow: auto;
}

.mainmenu-area .nav-row .menu-items li ul ul {
    left: 100%;
    top: 0;
}

.mainmenu-area .nav-row .menu-items li.menu-open>ul {
    transform: scaleY(1);
    opacity: 1;
}

.mainmenu-area .nav-row .menu-items>ul ul.over-items {
    display: grid;
    grid-template-columns: auto auto;
}

.mainmenu-area .nav-row .menu-items>ul ul li {
    padding: 3px 24px;
    min-width: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mainmenu-area .nav-row .menu-items>ul ul li a {
    color: #131313;
}

.mainmenu-area .nav-row .menu-items>ul ul li:hover>a {
    color: #131313;
}

.mainmenu-area {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/*=== 8. Header-CSS ===*/

.header-area {
    background-image: url('../images/theme-2/header-bg-iot.png');
    padding-top: var(--navbar-height);
    background-blend-mode: overlay;
    padding-bottom: 95px;
    background-size: cover;
}

.header-area .main-title {
    font-size: 49px;
    font-weight: 700;
    margin-bottom: 32px;
    margin-right: -30px;
    color: #ffffff;
}

.header-area .desc {
    color: #ffffff;
    margin-bottom: 56px;
}

.header-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
}

.play-button .play-icon {
    width: 56px;
    height: 56px;
    background-color: var(--primary-color);
    display: inline-block;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    border-radius: 100px;
    color: #ffffff;
    margin-right: 12px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding-left: 3px;
}

.play-button {
    color: #ffffff;
    display: inline-block;
}

.play-button:hover {
    color: #ffffff;
}

.play-button:hover .play-icon {
    background-color: #ffffff;
    color: var(--primary-color);
}

.header-area .header-image {
    margin: 40px -60px 0 0;
    text-align: center;
    position: relative;
    z-index: 1;
}

.header-area .header-image .header-image-bg {
    position: absolute;
    left: -0.5vw;
    bottom: 0;
    z-index: -1;
    width: 50vw;
    max-width: 880px;
}

/* Feature-Area-CSS */

.play-image-box {
    position: relative;
    z-index: 1;
}

.play-image-box .text-image {
    position: absolute;
    left: 50%;
    bottom: 80px;
    width: 100vw;
    max-width: 1920px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

.play-image-box .play-vr-image {
    text-align: center;
    margin: 0;
}

.feature-box-1 .icon {
    width: 64px;
    height: 64px;
    background-color: var(--primary-color);
    text-align: center;
    line-height: 64px;
    font-size: 32px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 100px;
    text-align: center;
    display: inline-block;
    margin-bottom: 40px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.feature-box-1 {
    text-align: center;
}

.feature-box-1:hover .icon {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.feature-box-1.purple-box .icon {
    background-color: #8B44FF;
}

.feature-box-1.green-box .icon {
    background-color: #3CB868;
}

.feature-box-1.yellow-box .icon {
    background-color: #FDA800;
}

.feature-box-1 .title {
    font-size: 32px;
    margin-bottom: 12px;
    font-weight: 300;
}

.play-step-boxes {
    position: relative;
    z-index: 1;
}

.play-step-boxes:before {
    content: "";
    position: absolute;
    left: 27%;
    top: 80px;
    height: 5px;
    width: 47%;
    background: url('../images/theme-2/dotted-line.svg') no-repeat scroll center center / auto;
}

/* Feedback-Area-CSS */

.feedback-area {
    background: url('../images/theme-2/feedback-bg.jpg') no-repeat scroll right top / cover;
    z-index: 1;
}

.feedback-area .section-padding {
    padding-top: 90px;
    padding-bottom: 115px;
}

.icon-box-1 .icon {
    width: 64px;
    height: 64px;
    text-align: center;
    line-height: 64px;
    background-color: var(--primary-color);
    font-size: 32px;
    color: #ffffff;
    border-radius: 6px;
    margin-bottom: 24px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0px 0px 0px rgba(255, 112, 49, 0.3);
    box-shadow: 0px 0px 0px rgba(255, 112, 49, 0.3);
}

.icon-box-1:hover .icon {
    background-color: #ffffff;
    color: var(--primary-color);
    -webkit-box-shadow: 0px 15px 35px rgba(255, 112, 49, 0.3);
    box-shadow: 0px 15px 35px rgba(255, 112, 49, 0.3);
}

.icon-box-1 .title {
    font-size: 26px;
    margin-bottom: 8px;
    font-weight: 300;
}

.icon-box-1.white-box .title {
    color: #ffffff;
    position: relative;
}
/* .icon-box-1.white-box .title-number  {
    content: '1';
} */
.icon-box-1.white-box .title-number {
    content: "";
    position: absolute;
    left: -56px;
    background: #ed1c24;
    width: 39px;
    height: 39px;
    text-align: center;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 23px;
    border-top-left-radius: 26px;
    top: 9px;
}

.icon-box-1.white-box .desc {
    color: rgba(255, 255, 255, 0.7);
}

.feedback-area .feedback-image {
    margin: 0;
    width: 35vw;
    float: left;
    position: relative;
    z-index: -1;
    max-width: 900px;
}

/* -- Call-To-Actions -- */

.action-image {
    position: relative;
}

.action-image .main-image {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 390px;
    height: 390px;
    z-index: -1;
}
.services-section .main-image {
    position: absolute;
    left: 47px;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 390px;
    height: 390px;
    background: #1f1146 !important;
    border-radius: 50% !important;
    z-index: -1;
}

.action-image .bg-image {
    max-width: 55%;
}

.actions-two .action-image .main-image {
    left: auto;
    right: 0;
}

.settings-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.settings-list li {
    margin-bottom: 16px;
    padding-left: 19px;
    position: relative;
}

.settings-list li:last-child {
    margin-bottom: 0;
}

.settings-list li:before {
    content: "";
    margin-right: 12px;
    height: 9px;
    width: 9px;
    background: var(--primary-color);
    position: absolute;
    left: 0;
    top: 8px;
}

/* Testimonial-CSS */

.testimonial-area {
    background-color: #170B37;
    z-index: 1;
}

.testimonial-image {
    margin: 0;
    position: relative;
    z-index: 1;
}

.testimonial-image .testi-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 54vw;
    max-width: 900px;
}

.rating-star {
    display: inline-block;
    position: relative;
    color: #FDB12E;
}

.rating-star .star {
    font-family: 'landshop' !important;
}

.rating-star .star:before {
    content: "\e960 \e960 \e960 \e960 \e960";
}

.rating-star .back {
    opacity: 0.4;
}

.rating-star .front {
    position: absolute;
    left: 0;
    top: 0px;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    z-index: 2;
}

.testimonial-item {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    position: relative;
    -webkit-box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
}

/* .testimonial-item:after {
    content: url(../images/theme-2/quote.png);
    position: absolute;
    right: 40px;
    bottom: 40px;
} */

.testimonial-slider {
    height: auto;
}

.testimonial-item .rating-star {
    margin-bottom: 24px;
}

.testimonial-item .desc {
    margin-bottom: 24px;
}

.testimonial-item .client-photo {
    width: 64px;
    height: 64px;
    border-radius: 100px;
    margin: 0;
    float: left;
    margin-right: 16px;
}

.testimonial-item .client-photo img{ 
    border-radius: 100px;
} 
.testimonial-item .title {
    font-size: 24px;
    margin-bottom: 4px;
}
.testimonial-item .play-button {
    position: absolute;
    bottom: 24px;
    right: 29px;
}
.testimonial-item .play-button .play-icon {
    width: 34px;
    height: 34px;
    line-height: 30px;
    margin-right: 5px;
}
.testimonial-item .play-button .play-icon svg {
    width: 13px;
}
.testimonial-item .play-button:hover .play-icon {
    background-color: #247274;
    color: #a3fcff;
}
/* -- call-to-action-- */
.call-to-action {
    padding: 76px 0;
}
/* -- Product-Area-CSS-- */

.product-slider {
    margin: -60px 0;
}

.product-box {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 40px 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 0;
    -webkit-box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.04);
}

.product-box.color-1 {
    background-color: #ECE6F8;
}

.product-box.color-2 {
    background-color: #E6F5EE;
}

.product-box.color-3 {
    background-color: #FFEDED;
}

.product-box.color-4 {
    background-color: #E7EEFE;
}

.product-box .thumb {
    margin-bottom: 32px;
}

.product-box .title {
    font-size: 24px;
    margin-bottom: 12px;
}

.product-box .price-and-rating>* {
    margin: 0 12px;
}

.product-box .price-and-rating {
    margin: 0 -12px;
}

.product-box .hover {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-image: -o-linear-gradient(top, rgba(19, 19, 19, 0.65) 0%, rgba(19, 19, 19, 0.48) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 19, 19, 0.65)), to(rgba(19, 19, 19, 0.48)));
    background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.65) 0%, rgba(19, 19, 19, 0.48) 100%);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    z-index: 3;
}

.product-box:hover .hover {
    opacity: 1;
    height: 100%;
}

.product-box .hover .action {
    width: 56px;
    height: 56px;
    text-align: center;
    line-height: 54px;
    background-color: #ffffff;
    color: var(--heading-color);
    border-radius: 100px;
    margin: 8px;
    font-size: 20px;
}

.product-box .hover .action:hover {
    background-color: var(--heading-color);
    color: #ffffff;
}

.lity-content {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 6px;
    overflow: hidden;
}

/*=== 17. Footer-CSS ===*/

.footer-area {
    background-color: #170B37;
    color: rgba(255, 255, 255, 0.7);
    --body-color: rgba(255, 255, 255, 0.7);
    --heading-color: rgba(255, 255, 255, 1);
}

.footer-area .section-padding {
    padding: 80px 0;
}

.footer-widget .footer-logo {
    display: inline-block;
    margin-bottom: 22px;
}

.footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 24px 0;
    text-align: center;
}

.social-menu {
    list-style: none;
    margin: -10px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.social-menu li a {
    width: 37px;
    height: 37px;
    line-height: 37px;
    display: inline-block;
    text-align: center;
    background-color: #ffffff;
    color: var(--body-color);
    border-radius: 100px;
    margin: 6px;
    -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.05);
}

.social-menu li:hover a {
    background-color: var(--primary-color);
    color: #ffffff;
}

.footer-area .social-menu li a {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.footer-area .social-menu li a:hover {
    background-color: var(--primary-color);
}

.footer-widget .desc {
    margin-bottom: 24px;
}

.footer-widget .widget-title {
    margin-bottom: 24px;
}

.block-list {
    list-style: none;
    margin: -8px 0;
    padding: 0;
}

.block-list li {
    display: block;
}

.block-list li a {
    color: var(--body-color);
    display: inline-block;
    margin: 8px 0;
}

.block-list li:hover a {
    color: var(--primary-color);
}

.input-control {
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    display: inline-block;
    padding: 15px 24px;
    font-weight: 400;
    outline: none;
    line-height: 1.445em;
    -webkit-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
}

.subscribe-form .input-control {
    width: calc(100% - 66px);
    padding-left: 44px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.subscribe-form {
    position: relative;
    z-index: 1;
}

.subscribe-form .fa-envelope{
    position: absolute;
    left: 16px;
    top: 18px;
    z-index: 2;
}

.subscribe-form button[type="submit"] {
    width: 56px;
    height: 56px;
    font-size: 20px;
    text-align: center;
    line-height: 56px;
    border-radius: 4px;
    border: none;
    background-color: var(--primary-color);
    color: #ffffff;
    float: right;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 0 0 rgba(255, 107, 49, 0.3);
    box-shadow: 0 0 0 rgba(255, 107, 49, 0.3);
}

.subscribe-form button[type="submit"]:hover {
    background-color: #ffffff;
    color: var(--primary-color);
    -webkit-box-shadow: 0px 15px 30px rgba(255, 107, 49, 0.3);
    box-shadow: 0px 15px 30px rgba(255, 107, 49, 0.3);
}

.subscribe-form .mc-error {
    position: absolute;
    left: 0;
    top: 100%;
    padding-top: 10px;
}

.mainmenu-area {
    background-color: transparent;
    /* transition: all 0.3s ease; */
    /* Other styles */
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Add any additional styles for the sticky state */
  }
  

  
  /*****/
  .note-content {
    max-height: 50px; /* Set a max height for the collapsed state */
    overflow: hidden; /* Hide overflow for collapsed text */
    transition: max-height 0.3s ease-in-out; /* Smooth transition */
  }
  
  .note-content.expanded {
    max-height: 100% /* Set a larger max height for the expanded state */
  }
  
  .iulJbw.iulJbw.iulJbw {
    background-color: #37afb3 !important;
}

.incubation-container {
    text-align: center;
    padding: 15px;
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
    background: #fdfdfd;
    border-radius: 8px;
}

.startup-logo {
    max-width: 100%;
    max-height: 150px;
    margin: 0 auto 15px;
    display: block;
    border-radius: 8px;
}

.incubation-message {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    line-height: 1.5;
}

.incubation-message strong {
    color: #1a73e8;
}

@media (max-width: 768px) {
    .incubation-container {
        padding: 10px;
        margin: 15px auto;
    }

    .incubation-message {
        font-size: 14px;
        /* Reduce font size for smaller screens */
    }

    .startup-logo {
        max-height: 120px;
        /* Further reduce image height on smaller screens */
    }
}

@media (max-width: 480px) {
    .incubation-container {
        padding: 8px;
    }

    .incubation-message {
        font-size: 13px;
    }
}
