
/* Main container for the services section */
.services-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 3rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Funnel Display", sans-serif;
}

/* Service Card Styling */
.service-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    opacity: 0;
    animation: fadeInUp 0.6s forwards;
    
}



/* Alternating image layout */
.left-image {
    flex-direction: row;
    /* Image on the left */
}

.right-image {
    flex-direction: row-reverse;
    /* Image on the right */
}

.service-image {
    flex: 0 0 45%;
    height: 600px;
    background-size: cover;
    background-position: center;
}

.service-content {
    flex: 1;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.service-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #fff;
    padding: 1rem;
    background: linear-gradient(135deg, #009ea3, #003537);
    /* Stylish gradient background */
    margin-bottom: 1.5rem;
}

.service-bullets {
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
    color: #555;
}

.bullet-item {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
    color: #333;
}

.bullet-icon {
    font-size: 1.5rem;
    color: #009ea3;
    /* Stylish color for bullets */
    margin-right: 0.8rem;
}

.book-now {
    padding: 0.8rem 2rem;
    background-color: #009ea3;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    align-self: flex-start;
    transition: background-color 0.3s ease;
}

.book-now:hover {
    background-color: #006164;;
}

/* Animation for Fade-in */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Service Card Delay */
.service-card:nth-child(1) {
    animation-delay: 0.1s;
}

.service-card:nth-child(2) {
    animation-delay: 0.3s;
}

.service-card:nth-child(3) {
    animation-delay: 0.5s;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .service-card {
        flex-direction: column;
        align-items: center;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

    }

    .service-image {
        width: 100%;
        height: 350px;
    }

    .service-content {
        padding: 1rem;
    }

    .book-now {
        width: 100%;
        text-align: center;
    }
}

.service-list {
    list-style: none;
    padding: 0;
    margin: 40px auto;
    max-width: 600px;
    /* Center the list */
    font-size: 1rem;
    line-height: 1.8;
}

.service-list li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 25px;
    position: relative;
}

/* Custom Bullet Style */
.bullet {
    width: 10px;
    height: 10px;
    background-color: #009ea3;
    /* Modern purple color */
    border-radius: 50%;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
}

/* Text Style for Service */
.service {
    font-weight: 600;
    color: #333;
    transition: color 0.3s ease;
    padding-left: 10px;
    /* Adds space between bullet and text */
}

/* Hover Effect */
.service-list li:hover .bullet {
    background-color: #c34539;
    /* Change bullet color on hover */
    transform: scale(1.2);
    /* Slightly grow the bullet */
}

.service-list li:hover .service {
    color: #ff6f61;
    /* Change text color on hover */
}


.btn {
    padding: 10px 20px;
    background: linear-gradient(135deg, #009ea3, #003537);
    color: #fff;
    width: max-content;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    text-align: left;
}

.btn:hover {
    background-color: #003537;
}





