/* General Container */
.blog-reader-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #444;
    background: #fff;
}

/* Blog Image */
.blog-reader-image-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-reader-image {
    width: 100%;
    object-fit: cover;
    max-height: 400px;
}

/* Blog Title */
.blog-reader-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 20px 0 10px;
    color: #222;
}

/* Meta Information */
.blog-reader-meta {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #555;
}

.blog-reader-author {
    font-weight: bold;
}

.blog-reader-date {
    color: #777;
}

/* Blog Content */
.blog-reader-content {
    padding: 20px 0;
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.blog-reader-content p {
    margin-bottom: 15px;
}

.blog-reader-content img {
    width: 100%;
    margin: 20px 0;
}

/* Loader */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f8f8f8;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ddd;
    border-top: 4px solid #444;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loading-container p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #444;
}

/* Error State */
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #ffe6e6;
    color: #b71c1c;
    text-align: center;
    padding: 20px;
}

.retry-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background: #b71c1c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.retry-btn:hover {
    background: #a11212;
}

/* Back Button */
.back-button {
    display: inline-block;
    margin-bottom: 20px;
    padding: 8px 15px;
    background: #f0f0f0;
    color: #555;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
    background: #e0e0e0;
    color: #333;
}

/* All Blogs Button */
.all-blogs-container {
    text-align: center;
    margin-top: 40px;
}

.all-blogs-button {
    display: inline-block;
    padding: 10px 20px;
    background: #006866;
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.all-blogs-button:hover {
    background: #022725;
    color: #fff;
}