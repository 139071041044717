@media screen and (max-width: 1600px) {
    .header-area {
        padding-top: 130px;
    }
    .header-area .main-title {
        font-size: 49px;
    }
    .header-area .info-box {
        bottom: 80px;
    }
    .header-area .header-image {
        margin: 0 -50px 0 50px;
    }
    .action-tip.tip-1 {
        right: 90px;
    }
    .action-tip.tip-2 {
        right: 40px;
    }
    .action-tip.tip-3 {
        left: 90px;
    }
    .action-tip.tip-4 {
        left: 30px;
    }
    .header-area .header-circle-text {
        right: 3vw;
    }
}

@media screen and (max-width: 1200px) {
    .header-area .main-title {
        font-size: 60px;
    }
    .play-step-boxes:before {
        display: none;
    }
    .feedback-area .feedback-image {
        float: none;
        margin: auto;
    }
}

@media screen and (max-width: 1024px) {
    .header-content .desc {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 992px) {
    
    .mainmenu-area .nav-row {
        padding: 12px 0;
    }
    .mainmenu-area .nav-row .navbar-collapse {
        background-color: rgba(0, 0, 0, 0.9);
    }
    .mainmenu-area.sticky .nav-row .navbar-collapse {
        background-color: #fffFFF
    }
    .mainmenu-area .nav-row .menu-items>ul {
        flex-direction: column;
        width: 100%;
    }
    .mainmenu-area .nav-row .menu-items>ul>li {
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid rgba(23, 11, 55, 0.1);
        flex-wrap: wrap;
    }
    .mainmenu-area .nav-row .menu-items li ul {
        position: static;
        order: 1;
        width: 100%;
        display: none !important;
        margin-top: 10px;
    }
    .mainmenu-area .nav-row .menu-items li.menu-open>ul {
        display: block !important;
    }
    .mainmenu-area .nav-row .menu-items li.menu-open>ul.over-items {
        display: grid !important;
    }
    .mainmenu-area .nav-row .menu-items ul li:not(.menu-open) .plus:before {
        transform: rotate(90deg);
    }
    .mainmenu-area .nav-row .menu-items>ul>li>a {
        line-height: 1.6em;
    }
    .mainmenu-area .nav-row .menu-items {
        max-height: calc(100vh - var(--navbar-height));
        overflow-y: auto;
    }

    .header-area {
        padding-top: 137px;
        background-size: cover;
        /* background-position: -122px 0; */
    }
    .header-content {
        margin-bottom: 40px;
    }
    .header-area .header-image {
        margin: 20px 0 0 2vw;
        text-align: center;
    }
    .header-area .header-image .header-image-bg {
        width: 80vw;
        right: 0;
        margin: 0 auto;
    }
    .header-area .header-circle-text {
        right: 20vw;
        top: 15%;
    }
    .header-area .main-title {
        margin-right: 0;
        font-size: 35px;
    }
    .icon-box-1.white-box .title-number {
        left: 64px;
        top: 2px;
    }
    .feedback-area .feedback-image {
        width: 100%;
    }
    .testimonial-image .testi-bg {
        width: 80vw;
        right: 0;
        margin: auto;
        top: -37px;
        bottom: initial;
    }
} 

 @media screen and (min-width: 768px) {
    .mainmenu-area .nav-row .menu-items li:hover>ul {
        transform: scaleY(1);
        opacity: 1;
    }
    .mainmenu-area .nav-row .menu-items ul li:hover>.plus:before {
        transform: rotate(0deg);
    }

    .mainmenu-area .nav-row .menu-items>ul>li {
        padding: 0 13px;
    }
 }    
/*    .feature-box-2.align-right {
        padding-left: 0;
        padding-right: 88px;
        text-align: right;
    }
    .feature-box-2.align-right .icon {
        left: auto;
        right: 0;
    }
  
    .header-area {
        padding-top: 178px !important;
        padding-bottom: 25px;
        background-size: cover;
        background-repeat: repeat;
        background-position: -106px;
    }
    .header-area .header-circle-text {
        right: 10vw;
    }
    .header-area .header-circle-text {
        width: 110px;
        height: 110px;
    }
    .header-area .header-circle-text .middle-icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }
    .header-area .header-circle-text .middle-icon img {
        width: 50%;
    }
    .header-area .header-circle-text svg {
        max-width: 94%;
    }
    .section-title .title {
        font-size: 38px;
    }
    .section-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .section-padding-top {
        padding-top: 60px;
    }
    .section-padding-bottom {
        padding-bottom: 60px;
    }
    .header-area .main-title {
        font-size: 34px !important;
        margin-bottom: 16px;
    }
    .feature-box-1 .icon {
        margin-bottom: 24px;
        font-size: 22px;
    }
    .feature-box-1 .title {
        font-size: 22px;
    }
    .testimonial-item .title {
        font-size: 20px;
    }
    .section-title {
        margin-bottom: 19px !important;
    }
    .section-title .title {
        margin-bottom: 16px;
    }
    .action-image .main-image {
        width: 290px !important;
        height: 200px !important;
    }
    .testimonial-item {
        padding: 30px;
    }
    .testimonial-item .rating-star {
        margin-bottom: 10px;
    }
    .testimonial-item .client-photo {
        width: 50px;
        height: 50px;
    }
} */

@media screen and (max-width: 576px) {
    .section-title .title {
        font-size: 35px;
    }
    .header-area .header-circle-text {
        right: 5vw;
        top: 10%;
    }
    .header-content .desc {
        margin-bottom: 24px;
    }
    .header-area .info-box {
        left: 15px;
        bottom: 50px;
        padding: 10px;
    }
    .header-area .info-box .icon {
        width: 40px;
        height: 40px;
        line-height: 37px;
        margin-right: 8px;
    }
    .header-area .info-box .icon img {
        width: 50%;
    }
    .info-box .title {
        font-size: 16px;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .info-box .desc {
        font-size: 12px;
    }
    .info-box .content {
        display: flex;
        flex-direction: column;
    }
    .feature-box-2 .icon {
        position: static;
        margin-bottom: 24px;
        display: inline-block;
    }
    .feature-box-2 {
        padding-left: 0px;
        text-align: center;
    }
    .video-play-box {
        padding: 100px 0;
    }
    .video-play-box .play-button {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 20px;
    }
    .video-play-box .wave-effect {
        width: 60px;
        height: 60px;
    }
    .testimonial-item .desc {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .testimonial-item .desc span {
        font-size: 22px;
    }
    .header-actions a {
        margin-top: 15px;
    }
    .play-button .play-icon {
        width: 37px;
        height: 37px;
        line-height: 37px;
        font-size: 12px;
        margin-right: 0;
    }
    .play-button .label {
        display: none;
    }
}

/* === Responsive-Mobile-Menu-CSS === */

@media screen and (min-width: 1200px) {
    .mainmenu-area .nav-row .menu-items {
        display: block !important;
    }
}

@media screen and (max-width: 1200px) {
   
    .transparent-light .mainmenu-area:not(.sticky) .menu-items {
        --heading-color: #131313;
    }
    .mainmenu-area .nav-actions {
        order: inherit;
    }
    .mainmenu-area .nav-actions .primary-button.menu-toggle {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    :root {
        --navbar-height: 80px;
    }
    .section-padding-top {
        padding-top: 12px;
    }
    .how-it-works .action-image .bg-image {
        max-width: 100%;
    }
    .feedback-area .section-padding {
        padding-top: 70px;
        padding-bottom: 23px;
    }
}

@media screen and (max-width: 576px) {
    .mainmenu-area .nav-row .menu-items>ul ul.over-items {
        grid-template-columns: auto;
    }
    .primary-button .part {
        padding: 8px 20px;
        font-size: 14px;
    }
    .mainmenu-area .nav-row .nav-logo .logo {
        max-width: 150px;
    }
    .mainmenu-area .nav-actions .primary-button.cirlce .part {
        width: 34px;
        height: 34px;
        font-size: 14px;
    }
    .mainmenu-area .nav-actions .primary-button {
        margin-left: 10px;
    }
}

@media screen and (max-width: 440px) {
    .mainmenu-area .nav-actions .action-button {
        display: none;
    }
    .mainmenu-area .nav-actions .primary-button.cirlce .part {
        width: 30px;
        height: 30px;
    }
    .mainmenu-area .nav-row .nav-logo .logo {
        max-width: 120px;
    }
}