

.policy-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
}

.policy-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.policy-header h1 {
    font-size: 22px;
    margin: 0;
    color: #333;
}

.policy-header p {
    font-size: 14px;
    color: #666;
}

.policy-content {
    padding: 20px;
}

.policy-content h2 {
    font-size: 18px;
    color: #444;
    margin-top: 30px;
}

.policy-content h3 {
    font-size: 16px;
    color: #555;
    margin-top: 20px;
}

.policy-content p, .policy-content ul {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.policy-content ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .policy-container {
        padding: 20px;
    }

    .policy-header h1 {
        font-size: 28px;
    }

    .policy-content h2 {
        font-size: 22px;
    }

    .policy-content h3 {
        font-size: 18px;
    }

    .policy-content p, .policy-content ul {
        font-size: 15px;
    }
}
