/* General Styles */
.highlight-list-container {
    padding: 20px;
    background: #f9f9f9;
}

.highlight-list-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

/* Loader */
.loader {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
    animation: fade-in 0.8s ease-in-out infinite;
}

/* Error Message */
.error-message {
    text-align: center;
    color: #e74c3c;
    font-size: 1.2rem;
    background: #fdecec;
    padding: 10px;
    margin: 20px auto;
    max-width: 600px;
}

/* Highlight Cards */
.highlight-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.highlight-card {
    background: #ffffff;
    border: 1px solid #ddd;
    overflow: hidden;
    cursor: pointer;
}

.highlight-card:hover {
    transform: translateY(-2px);
}

.highlight-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.highlight-details {
    padding: 15px;
}

.highlight-title {
    font-size: 1.4rem;
    color: #2c3e50;
    margin: 10px 0;
}

.highlight-author,
.highlight-date {
    font-size: 0.9rem;
    color: #95a5a6;
    margin: 5px 0;
}

/* No Highlights Message */
.no-highlights-message {
    text-align: center;
    font-size: 1.5rem;
    color: #7f8c8d;
    margin-top: 20px;
}

/* Animations */
@keyframes fade-in {

    0%,
    100% {
        opacity: 0.8;
    }

    50% {
        opacity: 1;
    }
}

.highlight-content {
    font-size: 0.95rem;
    color: #7f8c8d;
    margin-top: 10px;
}

.read-more-link {
    color: #006e6b;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.read-more-link:hover {
    color: #002d2b;
}