/* General Styles */
.blog-list-container {
    padding: 20px;
    background: #f4f4f4;
}

.blog-list-title {
    text-align: center;
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 20px;
}

/* Loader */
.loader {
    text-align: center;
    font-size: 1.5rem;
    color: #555;
    animation: fade-in 0.8s ease-in-out infinite;
}

/* Error Message */
.error-message {
    text-align: center;
    color: #ff4d4f;
    font-size: 1.2rem;
    background: #ffe6e6;
    padding: 10px;
    margin: 20px auto;
    max-width: 600px;
}

/* Blog Cards */
.blog-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.blog-card {
    background: #fff;
    border: 1px solid #ddd;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-details {
    padding: 15px;
}

.blog-title {
    font-size: 1.5rem;
    color: #333;
    margin: 10px 0;
}

.blog-author,
.blog-date {
    font-size: 0.9rem;
    color: #777;
    margin: 5px 0;
}

/* No Blogs Message */
.no-blogs-message {
    text-align: center;
    font-size: 1.5rem;
    color: #777;
    margin-top: 20px;
}

/* Animations */
@keyframes fade-in {

    0%,
    100% {
        opacity: 0.8;
    }

    50% {
        opacity: 1;
    }
}

.blog-content {
    font-size: 0.9rem;
    color: #555;
    margin-top: 10px;
}

.read-more-link {
    color: #006168;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.read-more-link:hover {
    color: #004c4b;
}