/* Reader Container */
.highlight-reader-container {
    padding: 2rem 1rem;
    max-width: 800px;
    margin: 0 auto;
}

/* Back Button */
.back-button {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #00444b;
    text-decoration: none;
    font-weight: 600;
}

.back-button:hover {
    color: rgb(0, 176, 179);
}

/* Highlight Image */
.highlight-reader-image-wrapper {
    margin-bottom: 1.5rem;
}

.highlight-reader-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Title */
.highlight-reader-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
}

/* Meta Information */
.highlight-reader-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: #555;
}

.highlight-reader-author,
.highlight-reader-date {
    font-weight: 500;
}

/* Content */
.highlight-reader-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 2rem;
}

/* View All Highlights Button */
.all-highlights-container {
    text-align: center;
}

.all-highlights-button {
    display: inline-block;
    background-color: #006866;
    color: white;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.all-highlights-button:hover {
    background-color: #022725;
    color: white;
}

/* Loader and Error */
.loading-container,
.error-container {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #555;
}

.retry-btn {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.retry-btn:hover {
    background-color: #c9302c;
}