.appointment-bg {
    background-image:url(../../assets/images/theme-2/appointment-bg.jpg);
    background-size: 100%;
    background-position: center;
    height: 274px;
    /* filter: blur(1px); */
    background-repeat: no-repeat;
}

  
.sec-pad {
    padding: 60px 0;
  }
  
  .appointment-bg {
    align-items: center;
    display: flex;
  }
  
  .appointment-bg .appointment-bgli,
  .appointment-bgb {
    font-size: 20px;
    margin: 10px 0;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    word-wrap: break-word;
  }
  
  .appointment-bg .appointment-bgi {
    font-size: 18px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .appointment-bgail a {
    color: #000;
    text-decoration: none;
  }
  
  .appointment-bg .appointment-bgli b:hover {
    color: #f93;
  }
  
  .appointment-bg .appointment-bgli .fa-location-dot {
    color: #f44337;
    border: 2px solid #f4433790;
  }
  
  .appointment-bg .appointment-bgli .fa-phone {
    color: #00b055;
    border: 2px solid #00b05590;
  }
  
  .appointment-bg .appointment-bgli .fa-envelope {
    color: #ff6347;
    border: 2px solid #ff634790;
  }
  
  .appointment-bgail span {
    width: 400px;
    display: flex;
    justify-content: center;
  }
  .appointment-bgail span a {
    font-size: 20px;
    padding: 6px 12px;
    color: #000;
    border-radius: 50%;
    margin: 0px 5px;
  }
  .appointment-bgail span .fb {
    color: #3b5998;
    border: 3px solid #3b5998;
  }
  .appointment-bgail span .fb:hover {
    color: #fff;
    background-color: #3b5998;
  }
  
  .appointment-bgail span .insta {
    color: #833ab4;
    border: 3px solid #833ab4;
  }
  .appointment-bgail span .insta:hover {
    color: #fff;
    background-color: #833ab4;
  }
  
  .appointment-bgail span .twitter {
    color: #00acee;
    border: 3px solid #00acee;
  }
  .appointment-bgail span .twitter:hover {
    color: #fff;
    background-color: #00acee;
  }
  
  form.contFrm {
    max-width: 396px;
    margin: auto;
  }
  
  .inptFld {
    width: 100%;
    height: 50px;
    border: 0;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 0 20px;
    font-size: 16px;
    color: #000;
    background-color: #f4f4f4;
  }
  
  .inptFld:focus {
    outline-offset: -4px;
    outline: 1px solid #f93;
  }
  
  .contFrm textarea {
    height: 75px;
    padding-top: 5px;
  }
  .inptBtn {
    height: 50px;
    border: 0;
    background: var(--primary-color);
    font-size: 14px;
    color: #fff;
    margin: auto;
    letter-spacing: 1px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
  }
  
  /* Responcive css Start */
  
  @media (max-width: 991px) {
    .sec-pad {
      padding: 20px 0px;
    }
  
    .appointment-bg .appointment-bgli,
    .appointment-bgb {
      font-size: 18px;
    }
  
    .appointment-bg .appointment-bgi {
      font-size: 14px;
      padding: 6px;
      margin-right: 6px;
    }
  
    .inptFld {
      height: 40px;
      margin: 0 0 10px;
      padding: 0 14px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 767px) {
    .appointment-bgail span {
      width: auto;
    }
    .appointment-bgail span a {
      font-size: 18px;
      padding: 5px 10px;
      color: #000;
      border-radius: 50%;
      margin: 0px 5px 20px;
    }
  }
  
  @media (max-width: 575px) {
    
    .appointment-bg {
      border-radius: 10% 10% 0% 0% / 5% 5% 0% 0%;
    }
  
    .appointment-bg .appointment-bgi {
      border: none;
    }
    .appointment-bg .inptFld {
      height: 36px;
      margin: 0 0 8px;
      padding: 0 14px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .appointment-bg .appointment-bgli,
    .appointment-bgb {
      font-size: 16px;
    }
  }
  